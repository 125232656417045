body {
  background-color: #e28743;
  color: #eeeee4;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  margin: 0;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
}

h2 {
  font-size: 1.5rem;
  font-weight: bold;
}

.task-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.task {
  background-color: green;
  color: white;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  width: 300px;
  text-align: center;
}
